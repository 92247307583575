<template>
  <div class="detail-media fs14">
    <div
      class="detail-media-item"
      v-for="(mediaItem, index) in showMediaList"
      :key="index"
      :style="{ width: mediaWidth }"
      @click="openMedia(mediaItem)"
    >
      <div class="detail-media-box">
        <img
          v-if="mediaItem.type !== 'VIDEO'"
          class="cover-img"
          :src="
            showImgCover(
              mediaItem.file_url,
              mediaItem.type,
              mediaItem.file_suffix
            )
          "
          alt="cover-img"
        />
        <video
          v-else
          class="cover-img"
          preload="metadata"
          object-fit="contain"
          :controls="false"
          :src="`${mediaItem.file_url}#t=0.1`"
        />
        <img
          v-if="showIconCover(mediaItem)"
          class="play-icon"
          :src="require('@/assets/img/quickSend/' + mediaItem.type + '_2x.svg')"
          :style="{ width: mediaIconWidth, height: mediaIconWidth }"
          alt="paly"
        />
        <div
          v-if="
            ['LINK', 'MINIPROGRAM', 'FILE'].includes(mediaItem.type) &&
            isShowMediaTitle
          "
          class="media-item-title border-box"
        >
          {{
            mediaItem.title ||
            mediaItem.qw_link_title ||
            mediaItem.qw_miniprogram_title ||
            mediaItem.file_name ||
            mediaItem.name
          }}
        </div>
        <img
          v-if="isEdit"
          class="delete-icon"
          src="@/assets/img/welcome/delete.svg"
          @click.stop="deletItem(index)"
        />
      </div>
    </div>
    <div
      v-if="showMediaList.length < maxLength && isEdit"
      class="detail-media-item fw300 cl99 bdr5"
      @click="openUpload"
    >
      <div class="detail-media-box">
        <span class="add-file-btn">+</span>
      </div>
    </div>
    <PopupView ref="uploadCover" direction="below" :is_close="false">
      <div class="upload-pop-conten" @click.stop="">
        <div class="btn-box flex flex-justify-around pb32 pt25 pl10 pr10">
          <div
            v-for="(item, index) in uploadOption"
            :key="index"
            class="uploda-item"
            @click="onUpload(item.type)"
          >
            <div class="bdr5 bgf p15">
              <img
                class="upload-item-icon block"
                :src="require(`@/assets/${item.img}`)"
              />
            </div>
            <div class="fs12 lh17 tc cl17 pt5">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="cancel-btn pt15 cl17 tc fs14" @click="closePop">取消</div>
      </div>
    </PopupView>
  </div>
</template>

<script>
import { ImagePreview, Image as VanImage, Switch, Toast } from 'vant'
import {
  openMiniProgram,
  chooseImage,
  uploadImage
} from '../../utils/nwechat.js'
import bus from '@/utils/bus'
import PopupView from '@/components/popupView'
import { EVENTCONST } from '@/utils/constants'
import { getUploadByMedia } from '@/service/upload.service'
import { formatTaskItem } from '@/utils/sendMessage'

export default {
  name: 'MediaGride',
  components: {
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    PopupView
  },
  props: {
    mediaList: {
      // 附件列表
      type: Array,
      default: () => []
    },
    // detail: { //
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // },
    pageType: {
      // 页面类型
      type: String,
      default: () => ''
    },
    isEdit: {
      // 是否可编辑
      type: Boolean,
      default: () => false
    },
    mediaWidth: {
      // 附件宽度
      type: String,
      default: () => '33.33%'
    },
    mediaIconWidth: {
      // 附件icon宽度
      type: String,
      default: () => '36px'
    },
    isShowMediaTitle: {
      // 是否展示附件标题
      type: Boolean,
      default: () => true
    },
    isPreviewMedia: {
      // 是否可预览附件
      type: Boolean,
      default: () => true
    },
    maxLength: {
      // 附件最多数量
      type: Number,
      default: () => 9
    }
  },

  data() {
    return {
      member_userid: '',
      customer_userid: '',
      showMediaList: [],
      uploadOption: [
        {
          img: 'img/welcome/image.svg',
          text: '图片',
          type: 'IMAGE'
        },
        {
          img: 'img/welcome/link.svg',
          text: '网页',
          type: 'LINK'
        }
      ]
    }
  },

  created() {
    const _this = this

    bus.on(EVENTCONST.SENDTEXT, (link) => {
      if (link) {
        let mediaItem = {
          type: 'LINK',
          content: '【链接】',
          upload_url: '',
          qw_link_title: link,
          qw_link_url: link,
          description: ''
        }
        _this.showMediaList.push(mediaItem)
      }
    })
  },
  unmounted() {
    bus.off(EVENTCONST.SENDTEXT)
  },
  watch: {
    mediaList: {
      handler(newval = []) {
        if (this.pageType === 'task' || this.pageType === 'strategy') {
          this.showMediaList = formatTaskItem(newval)
        } else {
          this.showMediaList = newval
        }
      },
      immediate: true,
      deep: true
    },
    showMediaList: {
      handler(newval = []) {
        if (this.isEdit) {
          this.$emit('updateMediaList', newval)
        }
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    // 点击媒体
    async openMedia(item) {
      if (!this.isPreviewMedia) return false
      if (item.type == 'IMAGE' || item.type === 'GOODS_POSTER') {
        ImagePreview({
          images: [item.file_url],
          closeable: true
        })
      } else if (item.type == 'VIDEO') {
        window.open(item.file_url)
      } else if (item.type == 'MINIPROGRAM') {
        await openMiniProgram({
          appid: item.qw_miniprogram_appid,
          path: item.qw_miniprogram_page
        })
        // console.log('打开小程序')
      } else if (item.type == 'LINK') {
        window.open(item.qw_link_url)
      } else if (item.type == 'FILE') {
        // console.log('触发文件预览')
        let url = `/preview-page?file=${item.file_url}&type=${item.file_suffix}`
        window.open(url)
      }
    },

    //封面图片过滤
    showImgCover(url, type, file_suffix) {
      if (!url && type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      }
      if (!url) {
        return ''
      }
      if (!file_suffix && url) {
        var file_suffix = url.substring(url.lastIndexOf('.') + 1)
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/word_2x.svg')
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/pdf_2x.svg')
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/excel_2x.svg')
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/ppt_2x.svg')
      }
      let baseUrl = url.indexOf('http') === -1 ? 'https://' : ''
      return baseUrl + url
    },
    // 附件封面上的图标展示
    showIconCover(item) {
      if (['VIDEO', 'MINIPROGRAM', 'LINK'].includes(item.type)) {
        if (item.type == 'LINK' && !item.file_url) return false
        return true
      }
      return false
    },
    openUpload() {
      this.$refs.uploadCover.show()
    },
    closePop() {
      this.$refs.uploadCover.close()
    },
    // 删除附件
    deletItem(index) {
      this.mediaList.splice(index, 1)
    },
    // 上传文件
    onUpload(type) {
      this.$refs.uploadCover.close()

      if (type == 'IMAGE') {
        this.uploadImage()
      } else if (type == 'LINK') {
        this.$router.push({ name: 'edit-link' })
      }
    },
    // 上传图片
    async uploadImage() {
      try {
        const localIds = await chooseImage({
          count: this.maxLength - this.showMediaList.length
        })
        Toast.loading({
          message: '上传中',
          duration: 0,
          forbidClick: true
        })
        const uploadList = []

        for (let i = 0; i < localIds.length; i++) {
          const serverId = await uploadImage({
            localId: localIds[i],
            isShowProgressTips: 0
          })
          const { data, code } = await getUploadByMedia({
            file_type: '1',
            media_id: serverId
          })

          if (code == 200) {
            uploadList.push({
              type: 'IMAGE',
              content: '【图片】',
              name: data.file_name,
              file_url: data.file_url,
              file_id: data.file_id,
              media_id: data.media_id
            })
          }
        }
        Toast.clear()
        this.showMediaList = [...this.showMediaList, ...uploadList]
      } catch (error) {
        console.log(error)
        Toast.error('上传失败!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.p15 {
  padding: 15px;
}
.pb32 {
  padding-bottom: 32px;
}
.detail-media {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3px;
  margin: 0 -5px;

  .detail-media-item {
    box-sizing: border-box;
    width: 33.33%;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    .detail-media-box {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      border-radius: 5px;
      border: 1px solid #f5f5f5;
      overflow: hidden;
    }

    .cover-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: inline-block;
      object-fit: fill;
      border-radius: 5px;
    }

    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 36px;
      height: 36px;
      z-index: 5;
    }
  }

  .media-item-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    text-align: center;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.4);
  }
}
.add-file-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  font-size: 40px;
}
.delete-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 10;
  right: 3px;
  top: 3px;
}
.upload-pop-conten {
  padding-bottom: calc(15px + env(safe-area-inset-bottom) / 2);

  .btn-box {
    background-color: #efefef;
  }

  .upload-item-icon {
    width: 25px;
    height: 22px;
  }
}
</style>
